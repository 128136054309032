"use strict";

import { prefetchable } from "webpack-prefetcher";
import { getLCP, getFID, getCLS } from 'web-vitals';

import "./main.scss";

// _______________

// Il nome del modulo è dinamico
let ModuleObject = new Object;

ModuleObject['UI'] = prefetchable(() => import('./UI'));
ModuleObject['Gdpr'] = prefetchable(() => import('./Gdpr'));
ModuleObject['Measurements'] = prefetchable(() => import('./Measurements'));
ModuleObject['Register'] = prefetchable(() => import('./Register'));
ModuleObject['Cart'] = prefetchable(() => import('./Cart'));
ModuleObject['Coupon'] = prefetchable(() => import('./Coupon'));
ModuleObject['Checkout'] = prefetchable(() => import('./Checkout'));
ModuleObject['Product'] = prefetchable(() => import('./Product'));
ModuleObject['Credits'] = prefetchable(() => import('./Credits'));
ModuleObject['Ajax'] = prefetchable(() => import('./Ajax'));

// _______________

// All loaded modules are stored here

var loadedModules = [];

// _______________

/**
 * I nodi che necessitano di moduli dichiarano data-load[NomeModulo].
 * Questa parte si occupa di ricavare i nomi dei moduli e inizializzare i moduli corrispondenti.
 * E' possibile usare più nomi separati con virgola: data-load[UI,GDPR,Ecc]
 */

let $dataLoadElement_s = document.querySelectorAll('[data-load-js]');

$dataLoadElement_s.forEach(($dataLoadElement) => {

  // Ottiene tutti i nomi dei moduli
  let moduleNames = $dataLoadElement.getAttribute('data-load-js');

  // extract multiple module per single data-load-js
  let moduleName_s = moduleNames.split(',');

  moduleName_s.forEach((moduleName) => {

    // if yet loaded, continue
    if (loadedModules[moduleName] !== undefined) return;

    // store loaded module
    // at this point because next step is a promise
    loadedModules[moduleName] = true

    // inizializza il modulo per nome
    ModuleObject[moduleName].load().then((Module) => {
      Module.default.init();
    });

  });

});

// _______________

// Web Vitals -> to GTM
function sendToGTM({ name, delta, id }) {

  // Assumes the global `dataLayer` array exists, see:
  // https://developers.google.com/tag-manager/devguide

  dataLayer.push({
    event: 'web-vitals',
    event_category: 'Web Vitals',
    event_action: name,
    // The `id` value will be unique to the current page load. When sending
    // multiple values from the same page (e.g. for CLS), Google Analytics can
    // compute a total by grouping on this ID (note: requires `eventLabel` to
    // be a dimension in your report).
    event_label: id,
    // Google Analytics metrics must be integers, so the value is rounded.
    // For CLS the value is first multiplied by 1000 for greater precision
    // (note: increase the multiplier for greater precision if needed).
    event_value: Math.round(name === 'CLS' ? delta * 1000 : delta),
  });
}

getCLS(sendToGTM);
getFID(sendToGTM);
getLCP(sendToGTM);

// _______________